var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e025e066bf3d5948211bfef51c034561484cd347"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: 'https://9d86ce5387104e47945f54066aa478aa@o4504559796682752.ingest.sentry.io/4505073399955456',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  release: process.env.NEXT_PUBLIC_VERSION,
  environment:
    window.location.href.indexOf('tryloop.ai') != -1 ||
    window.location.href.indexOf('loop-prod.vercel.app') != -1 ||
    window.location.href.indexOf('loopai.xyz') != -1 ||
    window.location.href.indexOf('loopkitchen.xyz') != -1 ||
    window.location.href.indexOf('loopintelligence.com') != -1
      ? 'production'
      : window.location.href.indexOf('localhost') != -1
      ? 'development'
      : 'preview'
})
